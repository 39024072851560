<template>
  <form class="h-full flex flex-col" @submit.prevent="submit">
    <h6 class="mb-10">Verify with Bank Statement</h6>

    <p class="mb-5 text-ash-700 text-12">
      Upload a 6-month statement of your bank account.
    </p>

    <div class="flex flex-col flex-grow">
      <p class="font-bold mb-2 text-12 text-left">
        Bank Statement
      </p>

      <div class="flex flex-col flex-grow">
        <dropzone
          class="mb-3 mr-2 w-full"
          v-model="form.data.bank_statement.value"
          accept=".pdf"
        />

        <div
          v-if="getFirstError('bank_statement')"
          class="form-group-2-text mb-2 text-red-500"
        >
          {{ getFirstError("bank_statement") }}
        </div>
        <div class="bg-onboarding-300 flex items-center mb-10 pl-5 pr-2 py-2 rounded-sm text-xs text-ash-700">
          <span class="bg-blue-200 h-5 inline-flex items-center justify-center mr-3 rounded-full text-blue-500 w-5">
            <ion-icon name="alert-outline" />
          </span>
          <span>
            Your bank statement would determine your increased limit.
          </span>
        </div>

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="flex gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray-soft btn-md relative"
            :disabled="form.loading"
            @click="back"
          >
            <span>Back</span>
            <i class="absolute inline-flex left-20px top-1/2" style="transform: translateY(-50%);">
              <ion-icon name="arrow-back-outline" />
            </i>
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md relative w-full"
            :disabled="form.loading || isVerified"
          >
            <sm-loader v-if="form.loading" class="sm-loader-white" />
            <span v-else>Submit</span>
            <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
              <ion-icon name="arrow-forward-outline" />
            </i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ManualUploadForm',
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: "bank_statement", value: null },
      ]),
    };
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    back() {
      this.$emit('back');
    },
    async submit() {
      this.googleEvent("Sign Up", "bank_statement");

      if (!this.validateForm(this.form)) {
        return false;
      }
      const data = new FormData();

      data.append(
        "bank_statement",
        this.form.data.bank_statement.value
      );

      this.form.error = false;
      this.form.setLoading();

      await this.sendRequest("personal.onboarding.documents.bankStatement", {
        data,
        success: (response) => {
          const { data: { user, documents } } = response.data;
          this.updateUser({ ...user, documents })
            .then(() => {
              this.$emit("verify-manual-upload", { user, documents });
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(
            this.form,
            error?.response?.data?.errors
          );
        },
      });

      this.form.setLoading(false);
    },
  },
};
</script>
